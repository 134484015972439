import React from 'react';
import { BadgeProps } from './UtilityProps';

const Badge = ({ children, colors, size = 'md', className }: BadgeProps) => {
	let sizeClasses = 'px-2 py-1';
	switch (size) {
		case 'xs':
			sizeClasses = 'px-2 py-0.5 text-[10px]';
			break;
		case 'sm':
			sizeClasses = 'px-2 py-0.5 text-sm';
			break;
		case 'md':
			sizeClasses = 'px-2 py-1 text-base';
			break;
		case 'lg':
			sizeClasses = 'px-3 py-1.5 text-base';
			break;
		case 'xl':
			sizeClasses = 'px-4 py-2 text-lg';
			break;
	}

	return (
		<div className={`flex items-center space-x-2 ${className}`}>
			<div
				className={`flex items-center justify-center ${sizeClasses ? sizeClasses : 'px-2 py-1'} rounded-full ${
					colors ? colors : 'bg-primary text-white'
				} font-medium`}
			>
				{children}
			</div>
		</div>
	);
};

export default Badge;
